.newAppRoute{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    width: 100vw;
}
.newAppRoute hr {
    width: 100%;
    border: 1px solid #bbb;
    margin: 20px 0;
}
.newAppRoute h1 {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
    color: #fff;
}
.newAppRoute h2 {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
    color: #fff;
}
.newAppRoute h3 {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 1rem;
    color: #fff;
}
.newAppRoute form.deployForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    margin: 20px 0;
    gap: 20px;
}
.newAppRoute form.deployForm input {
    width: 400px;
    padding: 10px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* margin-bottom: 10px; */
    color: #333;
    font-size: 16px;
    margin: 0 16px;
}
.newAppRoute form.deployForm input:focus {
    outline: none;
}
.newAppRoute form.deployForm select {
    width: 180px;
    padding: 10px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* margin-bottom: 10px; */
    color: #333;
    font-size: 16px;
    background-color: #fff;
}
.newAppRoute form.deployForm select:focus {
    outline: none;
}
.newAppRoute form.deployForm button {
    width: 180px;
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #fff;
    color: #2b2d2d;
    font-size: 20px;
    font-weight: 300;
    transition: all 0.3s ease-in-out;
    /* margin-bottom: 10px; */
  
    /* margin: 0 16px; */
}
.newAppRoute form.deployForm button:hover {
    cursor: pointer;
    background-color: #2b2d2d;
    color: #fff;
    border: 1px solid #fff;
}
/* .newAppRoute .requiredFields { */
    /* border: 1px solid #fff; */
/* } */