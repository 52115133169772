.contactUs{
    width: 100vw;
    min-height: calc(100vh - 60px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    /* direction: ltr; */
    /* min-height: 70vh; */
}
.contactUsCover {
    background-image: url('https://omegacare-med.net/img/banners/contact_us.png');
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 60vh;
    z-index: -1;
}
.contactUsContainer {
    display: flex;
    background-color: #fff;
    width: 60%;
    height: 80%;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}
/* .contactUsContentTop {
    margin-bottom: 20px;
    background-color: blue;
} */
.contactUsContainer h1 {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}
.contactUsContainer p {
    font-size: 15px;
    font-weight: 400;
    /* margin-bottom: 20px; */
}
.contactUsContainer li {
    /* list-style: none; */
    margin: 10px 0 10px 10px;
    /* padding-inline-start:  */
    font-size: 14px;
}