.login {
    height: 100vh;
    width: 100vw;
}
.login .container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login .container h1 {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
    color: #fff;
}
.login .container p {
    font-size: 1.25rem;
    font-weight: 300;
    color: #fff;
}
.login .container input {
    width: 350px;
    height: 50px;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0 10px;
    font-size: 20px;
    font-weight: 300;
    color: #ccc;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    margin: 16px 0;
}
.login .container input:focus {
    outline: none;
    border-bottom: 1px solid #fff;
    color: #fff;
}
.login .container button {
    width: 350px;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: #fff;
    color: #2b2d2d;
    font-size: 20px;
    font-weight: 300;
    transition: all 0.3s ease-in-out;
    margin: 16px 0;

}
.login .container button:hover {
    cursor: pointer;
    background-color: #2b2d2d;
    color: #fff;
    border: 1px solid #fff;
}
