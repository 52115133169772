.appsList {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.appsList .container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.appsList .container .row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding: 20px; */
}
.appsList h1 {
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  color: #fff;
}
.appsList button {
  width: 200px;
  /* height: 35px; */
  padding: 5px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 300;
  transition: all 0.3s ease-in-out;
  margin: 0 16px;

  cursor: pointer;
  background-color: #2b2d2d;
  color: #fff;
  border: 1px solid #fff;

}
.appsList button:hover {
  border: none;
  background-color: #fff;
  color: #2b2d2d;
}

/* 
.appsList table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
}
.appsList table th {
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  color: #ccc;
  text-align: left;
  background-color: #2b2d2d;
}
.appsList table td {
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  color: #ccc;
  text-align: left;
  background-color: #2b2d2d;
}

} */

/* .appsList table a {
  color: #ccc;
  text-decoration: none;
  cursor: pointer;
} */

.no-decoration-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.inherit-color {
  color: inherit;
}

.hover-row:hover {
  background-color: #3b3d3d;
}