header.header {
    height: 60px;
    width: 100vw;
    background-color: #fffdfe;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 0 20px;
    
}
header.header .logo {
    font-size: 2rem;
    font-weight: 300;
    color: #2b2d2d;
}
header.header ul, header.header li {
    list-style: none;
}
header.header li {
    display: inline-block;
    margin-inline-end: 20px;
}
header.header a {
    text-decoration: none;
    color: #2b2d2d;
}
header.header a:hover {
    text-decoration: underline;
}