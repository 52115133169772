.appRoute {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
}
.appRoute > h1 {
    text-align: left;
    width: 85%;
    margin-top: 20px;
    margin-bottom: 5px;
    color: #ccc;

}
.appRoute .deletedAlert {
    width: 85%;
    margin: 28px 0;
}
.appRoute .deletedAlert a {
    text-decoration: none;
    color: inherit;
}
.appRoute .appDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.appRoute .appDetails table {
    width: 85%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ccc;
}
.appRoute .appDetails table th {
    padding: 10px;
    text-align: left;
    font-weight: 300;
    color: #fff;
    background-color: #2b2d2d;
}
.appRoute .appDetails table td {
    padding: 10px;
    font-weight: 300;
    color: #ccc;
}
.appRoute .appDetails table td:nth-child(1) {
    width: 25%;
}
/* .appRoute .appDetails table td:nth-child(2) {
    width: 50%;
} */
.appRoute .appDetails table tr:nth-child(even) {
    background-color: #2b2d2d;
}
.appRoute .appDetails table tr:nth-child(odd) {
    background-color: #2b2d2d;
}
.appRoute .deleteAppSection {
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column;
    width: 85%;
    border-radius: 2px;
    /* padding: 20px; */
    margin: 28px 0;
    /* margin: 20px 0; */
    background-color: #2b2d2d;
}
.appRoute .deleteAppSection > h1 {
    /* color: #b50000; */
    font-size: 35px;
    font-weight: 400;
    /* margin: 10px 0; */
    /* padding: 0 10px; */
    color: #ccc;
    margin-bottom: 5px;

    
}
.appRoute .deleteAppSection .deleteApp {
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    border: 1px solid #711523;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-radius: 2px;
    flex-direction: row;

}
.appRoute .deleteAppSection .deleteApp button{
    background-color: #711523;
    color: #fff;
    border: 1px solid #2b2d2d;
    padding: 10px;
    border-radius: 2px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}
/* .appRoute .deleteAppSection .deleteApp button:hover {
    background-color: #2b2d2d;
    color: #fff;
    border: 1px solid #711523;
} */
.appRoute .updateAppSection {
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column;
    width: 85%;
    border-radius: 2px;
    /* padding: 20px; */
    margin: 28px 0;
    /* margin: 20px 0; */
    background-color: #2b2d2d;
}
.appRoute .updateAppSection > h1 {
    /* color: #b50000; */
    font-size: 35px;
    font-weight: 400;
    /* margin: 10px 0; */
    /* padding: 0 10px; */
    color: #ccc;
    margin-bottom: 5px;

    
}
.appRoute .updateAppSection .updateApp {
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-radius: 2px;
    flex-direction: row;
}
.appRoute .updateAppSection .updateApp button{
    background-color: #2b2d2d;
    color: #fff;
    border: 1px solid #2b2d2d;
    padding: 10px;
    border-radius: 2px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}
.appRoute .updateAppSection .updateApp button:hover {
    background-color: #fff;
    color: #1d1d1d;
    border: 1px solid #2b2d2d;
}