footer.footer {
    /* display: flex;
    justify-content: center;
    align-items: center; */
  /* background-color: #f5f5f5; */
  padding: .2rem;
  margin-top: 1rem;
  width: 100vw;
  /* height: 15px */
}
footer.footer p {
    font-size: 0.8rem;
    /* margin: 0; */
    width: 100%;
    text-align: center;
    color: #ccc;


}