.homePage{
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    width: 100vw; */
}
.homePage hr {
    width: 100%;
    border: 1px solid #bbb;
    margin: 20px 0;
}
.homePage h1 {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
    color: #fff;
}
